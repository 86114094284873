<template>
<div v-if="isAdmin || checkAccess('counters')">
  <report-list-page
    page-title="Counter Report"
    page-icon="trending_up"
    :descending="true"
    :headers="headers"
    state-end-point="salesCounter.salesCounters"
    :actions="actions"
  />
</div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "CounterReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Order Date',
          value: 'orderDate'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          align: 'left',
          value: 'paymentStatus'
        },
        {
          text: 'Is Prepaid?',
          value: 'isPrepayment'
        },
        {
          text: 'Order Details',
          align: 'left',
          value: 'orderDetails'
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'amount'
        },
        {
          text: 'Publishable Amount',
          align: 'right',
          value: 'publishableAmount'
        },
        {
          text: 'Discount',
          align: 'right',
          value: 'discount'
        },
        {
          text: 'Total Amount',
          align: 'right',
          value: 'totalAmount'
        }
      ],
      actions: {
        load: 'loadSalesCounters',
      },
    }
  }
}
</script>

<style scoped>

</style>